<template>
  <header>
    <nav
      class="border-gray-200 bg-white dark:bg-[#121212] md:left-1/2 mx-auto md:fixed md:transform md:-translate-x-1/2 z-50 px-2 md:px-2"
      :class="
        isMapPage
          ? 'w-full md:w-[98%] md:mt-5 rounded-full md:py-2 px-1 md:px-5'
          : 'md:w-full py-3 px-1 max-w-screen-xl md:py-2'
      "
    >
      <div
        class="flex justify-between px-0.5 items-center w-full gap-4 md:px-0 md:py-0 md:grid md:grid-cols-[20%_80%] md:justify-between"
      >
        <a href="/" class="flex items-center rtl:space-x-reverse">
          <img src="/img/logo.svg" class="h-9 sm:h-14 md:h-14" alt="Logo" />
        </a>
        <div class="flex relative justify-end items-center md:w-full md:grid md:grid-rows-1 md:grid-cols-[90%_10%]">
          <div
            v-if="showSearchBar"
            class="flex items-center space-x-4 md:static left-0 right-0 z-50 md:px-1"
            :class="!showTopSearchButton && 'fixed bottom-2'"
          >
            <SearchBar
              v-if="showSearchBar"
              :popularSearches="popularSearches"
              :cityID="cityID"
              :currentPageName="currentPageName"
              class="w-full md:relative"
            />
          </div>
          <button
            @click="openModal"
            class="hidden md:block border-1 text-gray-800 dark:text-white border-2 border-solid border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-[10px] lg:px-5 py-[10px] md:col-start-2 md:justify-self-end md:align-self-end lg:py-2.5 mr-4 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
          >
            <div class="flex items-center justify-center w-full relative">
              <!-- Language icon -->
              <img src="/img/icons/language_flag.svg" class="dark:filter dark:invert dark:invert-1" />
              <!-- Flag image -->
              <img
                :src="`/img/flag/${flag}.svg`"
                class="h-3 md:h-3 rounded bg-cover absolute bottom-0 right-0 left-[4px] transform translate-x-1/3 translate-y-1/3"
                :alt="`${locale} Flag`"
              />
            </div>
          </button>
          <button v-if="isMobile" class="justify-self-end px-1 md:px-0">
            <img class="z-20 filter dark:invert dark:inver-1" @click="handleOpenNavBar" src="/img/icons/menu.svg" />
          </button>
        </div>
      </div>
      <NavigationMenu v-if="!isMobile && showSearchBar" :navItems="navItems" />
    </nav>
    <!-- Mobile Navigation Menu -->
    <NavigationMenu
      v-if="isMobileNavOpen"
      :navItems="navItems"
      :isMobileNavOpen="isMobileNavOpen"
      @closeMobileNav="isMobileNavOpen = false"
      @openContactUsChat="openContactUsChat"
      @showSettingsFor="(settingsName) => (showOnlySettings = settingsName)"
    />
    <AdaptableModal :isOpen="isModalOpen || !!showOnlySettings" @close="closeModal">
      <template #header>
        <div class="flex items-center space-x-2 w-full mb-0">
          <img src="/img/icons/language.svg" class="dark:filter dark:invert dark:invert-1" />
          <h2 class="text-lg font-semibold">{{ $t('Regional Setting') }}</h2>
        </div>
      </template>
      <RegionalSettings :showOnly="isMobile && showOnlySettings" :autoOpen="isMobile" @settingsUpdated="closeModal">
      </RegionalSettings>
    </AdaptableModal>
  </header>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import NavigationMenu from '~/components/Navigation/NavigationMenu';
import RegionalSettings from './RegionalSettings.vue';
import AdaptableModal from './Utils/AdaptableModal.vue';

const mixpanel = useMixpanel();

const nuxtApp = useNuxtApp();

const route = useRoute();
const router = useRouter();
const path = computed(() => route.path);
const pathName = computed(() => route.name);

const popularSearches = ref([]);
const currentPageName = ref(null);
const navItems = ref(null);

const { locale } = useI18n();

const isModalOpen = ref(false);

// For mobile only
const isMobileNavOpen = ref(false);
// For mobile only. Either 'currency' or 'language'
const showOnlySettings = ref(null);

const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
});

const { isMobile } = useDevice();

if (process.client) {
  var userLang = navigator.language || navigator.userLanguage;
}

const isMapPage = computed(() => {
  if (route?.name?.includes('map')) {
    return true;
  } else {
    return false;
  }
});

function openContactUsChat() {
  const button = document.getElementsByClassName('arcu-message-button')[0];
  button.click();
}

//This is needed to add a bottom margin to the search bar when on the product page because the product page has the booking button on the bottom of the page
const showTopSearchButton = computed(() => {
  return (route?.name?.includes('product') || route?.name?.includes('map')) ?? false;
});

const flag = computed(() => {
  switch (locale.value) {
    case 'en':
      if (userLang === 'en-GB') {
        return 'gb';
      } else {
        return 'us';
      }
    case 'fr':
      return 'fr';
    case 'de':
      return 'de';
    case 'es':
      return 'es';
    case 'it':
      return 'it';
    case 'pt':
      return 'pt';
    case 'ru':
      return 'ru';
    case 'zh':
      return 'cn';
    case 'nl':
      return 'nl';
    default:
      return 'us';
  }
});

try {
  watch(locale, () => {
    flag.value = flag.value;
  });
} catch (error) {
  console.error('Error in watch(locale, () => { ... }):', error);
}

const cityID = ref(props.data?.cityID);

nuxtApp.hook('page:finish', () => {
  updateSearchBarVisibility();
});

// Check if the path is the homepage or homepage with language prefix
const showSearchBar = ref((!pathName?.value?.includes('search') && !pathName?.value?.includes('index')) ?? false);

const openModal = () => {
  isModalOpen.value = true;
};

const closeModal = () => {
  showOnlySettings.value = null;
  isModalOpen.value = false;
};

const handleOpenNavBar = () => {
  if (isMobileNavOpen.value) {
    return;
  }
  mixpanel.track('Mobile Nav Bar Opened');
  isMobileNavOpen.value = true;
};

const updateSearchBarVisibility = () => {
  showSearchBar.value = !pathName?.value?.includes('search') && !pathName?.value?.includes('index');
};

// Initial check on component mount
onMounted(() => {
  if (cityID.value) {
    fetchMenuData();
  }

  router.isReady().then(() => {
    updateSearchBarVisibility(path.value);
  });
});

watch(cityID, (newCityID) => {
  if (newCityID) {
    fetchMenuData();
  }
});

async function fetchMenuData() {
  const response = await $fetch('/api/menu', {
    method: 'GET',
    params: {
      destId: cityID.value,
      lang: locale.value,
    },
  });

  navItems.value = response;
}

watch(props, () => {
  if (props.data && props.data.popularSearches) {
    popularSearches.value = props.data.popularSearches;
  }
  if (props.data) {
    cityID.value = props.data?.cityID;
  }
  if (props.data && props.data.currentPageName) {
    currentPageName.value = props.data.currentPageName;
  }
});
</script>

export const numberFormats = {
  'en-IE': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      name: 'Euro',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-IT': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      name: 'Euro',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-US': {
    currency: {
      style: 'currency',
      currency: 'USD',
      name: 'United States Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-UK': {
    currency: {
      style: 'currency',
      currency: 'GBP',
      name:'Great Britain Pound',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-AU': {
    currency: {
      style: 'currency',
      currency: 'AUD',
      name:'Australian Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-BR': {
    currency: {
      style: 'currency',
      currency: 'BRL',
      name:'Brazilian Real',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-CA': {
    currency: {
      style: 'currency',
      currency: 'CAD',
      name:'Canadian Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-CN': {
    currency: {
      style: 'currency',
      currency: 'CNY',
      name:'Chinese Yuan',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-IN': {
    currency: {
      style: 'currency',
      currency: 'INR',
      name:'Indian Rupees',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-JP': {
    currency: {
      style: 'currency',
      currency: 'JPY',
      name:'Japanese Yen',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-NZ': {
    currency: {
      style: 'currency',
      currency: 'NZD',
      name:'New Zealand Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-RU': {
    currency: {
      style: 'currency',
      currency: 'RUB',
      name:'Russian Rubles',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-SG': {
    currency: {
      style: 'currency',
      currency: 'SGD',
      name:'Singapore Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-KR': {
    currency: {
      style: 'currency',
      currency: 'KRW',
      name:'South Korean Won',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-SE': {
    currency: {
      style: 'currency',
      currency: 'SEK',
      name:'Swedish Krona',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-CH': {
    currency: {
      style: 'currency',
      currency: 'CHF',
      name:'Swiss Franc',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-AR': {
    currency: {
      style: 'currency',
      currency: 'ARS',
      name:'Argentine Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-HK': {
    currency: {
      style: 'currency',
      currency: 'HKD',
      name:'Hong Kong Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-MX': {
    currency: {
      style: 'currency',
      currency: 'MXN',
      name:'Mexican Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-NO': {
    currency: {
      style: 'currency',
      currency: 'NOK',
      name:'Norwegian Krone',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-IL': {
    currency: {
      style: 'currency',
      currency: 'ILS',
      name:'Israeli Shekel',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-AE': {
    currency: {
      style: 'currency',
      currency: 'AED',
      name:'Emirati Dirham',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-CL': {
    currency: {
      style: 'currency',
      currency: 'CLP',
      name:'Chilean Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'en-CO': {
    currency: {
      style: 'currency',
      currency: 'COP',
      name:'Colombian Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-FR': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      name: 'Euro',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-US': {
    currency: {
      style: 'currency',
      currency: 'USD',
      name: 'United States Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-UK': {
    currency: {
      style: 'currency',
      currency: 'GBP',
      name:'Great Britain Pound',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-AU': {
    currency: {
      style: 'currency',
      currency: 'AUD',
      name:'Australian Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-BR': {
    currency: {
      style: 'currency',
      currency: 'BRL',
      name:'Brazilian Real',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-CA': {
    currency: {
      style: 'currency',
      currency: 'CAD',
      name:'Canadian Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-CN': {
    currency: {
      style: 'currency',
      currency: 'CNY',
      name:'Chinese Yuan',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-IN': {
    currency: {
      style: 'currency',
      currency: 'INR',
      name:'Indian Rupees',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-JP': {
    currency: {
      style: 'currency',
      currency: 'JPY',
      name:'Japanese Yen',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-NZ': {
    currency: {
      style: 'currency',
      currency: 'NZD',
      name:'New Zealand Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-RU': {
    currency: {
      style: 'currency',
      currency: 'RUB',
      name:'Russian Rubles',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-SG': {
    currency: {
      style: 'currency',
      currency: 'SGD',
      name:'Singapore Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-KR': {
    currency: {
      style: 'currency',
      currency: 'KRW',
      name:'South Korean Won',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-SE': {
    currency: {
      style: 'currency',
      currency: 'SEK',
      name:'Swedish Krona',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-CH': {
    currency: {
      style: 'currency',
      currency: 'CHF',
      name:'Swiss Franc',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-AR': {
    currency: {
      style: 'currency',
      currency: 'ARS',
      name:'Argentine Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-HK': {
    currency: {
      style: 'currency',
      currency: 'HKD',
      name:'Hong Kong Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-MX': {
    currency: {
      style: 'currency',
      currency: 'MXN',
      name:'Mexican Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-NO': {
    currency: {
      style: 'currency',
      currency: 'NOK',
      name:'Norwegian Krone',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-IL': {
    currency: {
      style: 'currency',
      currency: 'ILS',
      name:'Israeli Shekel',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-AE': {
    currency: {
      style: 'currency',
      currency: 'AED',
      name:'Emirati Dirham',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-CL': {
    currency: {
      style: 'currency',
      currency: 'CLP',
      name:'Chilean Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'fr-CO': {
    currency: {
      style: 'currency',
      currency: 'COP',
      name:'Colombian Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-ES': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      name: 'Euro',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-US': {
    currency: {
      style: 'currency',
      currency: 'USD',
      name: 'United States Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-UK': {
    currency: {
      style: 'currency',
      currency: 'GBP',
      name:'Great Britain Pound',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-AU': {
    currency: {
      style: 'currency',
      currency: 'AUD',
      name:'Australian Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-BR': {
    currency: {
      style: 'currency',
      currency: 'BRL',
      name:'Brazilian Real',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-CA': {
    currency: {
      style: 'currency',
      currency: 'CAD',
      name:'Canadian Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-CN': {
    currency: {
      style: 'currency',
      currency: 'CNY',
      name:'Chinese Yuan',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-IN': {
    currency: {
      style: 'currency',
      currency: 'INR',
      name:'Indian Rupees',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-JP': {
    currency: {
      style: 'currency',
      currency: 'JPY',
      name:'Japanese Yen',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-NZ': {
    currency: {
      style: 'currency',
      currency: 'NZD',
      name:'New Zealand Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-RU': {
    currency: {
      style: 'currency',
      currency: 'RUB',
      name:'Russian Rubles',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-SG': {
    currency: {
      style: 'currency',
      currency: 'SGD',
      name:'Singapore Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-KR': {
    currency: {
      style: 'currency',
      currency: 'KRW',
      name:'South Korean Won',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-SE': {
    currency: {
      style: 'currency',
      currency: 'SEK',
      name:'Swedish Krona',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-CH': {
    currency: {
      style: 'currency',
      currency: 'CHF',
      name:'Swiss Franc',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-AR': {
    currency: {
      style: 'currency',
      currency: 'ARS',
      name:'Argentine Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-HK': {
    currency: {
      style: 'currency',
      currency: 'HKD',
      name:'Hong Kong Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-MX': {
    currency: {
      style: 'currency',
      currency: 'MXN',
      name:'Mexican Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-NO': {
    currency: {
      style: 'currency',
      currency: 'NOK',
      name:'Norwegian Krone',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-IL': {
    currency: {
      style: 'currency',
      currency: 'ILS',
      name:'Israeli Shekel',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-AE': {
    currency: {
      style: 'currency',
      currency: 'AED',
      name:'Emirati Dirham',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-CL': {
    currency: {
      style: 'currency',
      currency: 'CLP',
      name:'Chilean Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'es-CO': {
    currency: {
      style: 'currency',
      currency: 'COP',
      name:'Colombian Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-DE': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      name: 'Euro',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-US': {
    currency: {
      style: 'currency',
      currency: 'USD',
      name: 'United States Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-UK': {
    currency: {
      style: 'currency',
      currency: 'GBP',
      name:'Great Britain Pound',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-AU': {
    currency: {
      style: 'currency',
      currency: 'AUD',
      name:'Australian Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-BR': {
    currency: {
      style: 'currency',
      currency: 'BRL',
      name:'Brazilian Real',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-CA': {
    currency: {
      style: 'currency',
      currency: 'CAD',
      name:'Canadian Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-CN': {
    currency: {
      style: 'currency',
      currency: 'CNY',
      name:'Chinese Yuan',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-IN': {
    currency: {
      style: 'currency',
      currency: 'INR',
      name:'Indian Rupees',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-JP': {
    currency: {
      style: 'currency',
      currency: 'JPY',
      name:'Japanese Yen',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-NZ': {
    currency: {
      style: 'currency',
      currency: 'NZD',
      name:'New Zealand Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-RU': {
    currency: {
      style: 'currency',
      currency: 'RUB',
      name:'Russian Rubles',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-SG': {
    currency: {
      style: 'currency',
      currency: 'SGD',
      name:'Singapore Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-KR': {
    currency: {
      style: 'currency',
      currency: 'KRW',
      name:'South Korean Won',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-SE': {
    currency: {
      style: 'currency',
      currency: 'SEK',
      name:'Swedish Krona',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-CH': {
    currency: {
      style: 'currency',
      currency: 'CHF',
      name:'Swiss Franc',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-AR': {
    currency: {
      style: 'currency',
      currency: 'ARS',
      name:'Argentine Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-HK': {
    currency: {
      style: 'currency',
      currency: 'HKD',
      name:'Hong Kong Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-MX': {
    currency: {
      style: 'currency',
      currency: 'MXN',
      name:'Mexican Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-NO': {
    currency: {
      style: 'currency',
      currency: 'NOK',
      name:'Norwegian Krone',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-IL': {
    currency: {
      style: 'currency',
      currency: 'ILS',
      name:'Israeli Shekel',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-AE': {
    currency: {
      style: 'currency',
      currency: 'AED',
      name:'Emirati Dirham',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-CL': {
    currency: {
      style: 'currency',
      currency: 'CLP',
      name:'Chilean Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'de-CO': {
    currency: {
      style: 'currency',
      currency: 'COP',
      name:'Colombian Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-IT': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      name: 'Euro',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-US': {
    currency: {
      style: 'currency',
      currency: 'USD',
      name: 'United States Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-UK': {
    currency: {
      style: 'currency',
      currency: 'GBP',
      name:'Great Britain Pound',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-AU': {
    currency: {
      style: 'currency',
      currency: 'AUD',
      name:'Australian Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-BR': {
    currency: {
      style: 'currency',
      currency: 'BRL',
      name:'Brazilian Real',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-CA': {
    currency: {
      style: 'currency',
      currency: 'CAD',
      name:'Canadian Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-CN': {
    currency: {
      style: 'currency',
      currency: 'CNY',
      name:'Chinese Yuan',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-IN': {
    currency: {
      style: 'currency',
      currency: 'INR',
      name:'Indian Rupees',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-JP': {
    currency: {
      style: 'currency',
      currency: 'JPY',
      name:'Japanese Yen',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-NZ': {
    currency: {
      style: 'currency',
      currency: 'NZD',
      name:'New Zealand Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-RU': {
    currency: {
      style: 'currency',
      currency: 'RUB',
      name:'Russian Rubles',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-SG': {
    currency: {
      style: 'currency',
      currency: 'SGD',
      name:'Singapore Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-KR': {
    currency: {
      style: 'currency',
      currency: 'KRW',
      name:'South Korean Won',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-SE': {
    currency: {
      style: 'currency',
      currency: 'SEK',
      name:'Swedish Krona',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-CH': {
    currency: {
      style: 'currency',
      currency: 'CHF',
      name:'Swiss Franc',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-AR': {
    currency: {
      style: 'currency',
      currency: 'ARS',
      name:'Argentine Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-HK': {
    currency: {
      style: 'currency',
      currency: 'HKD',
      name:'Hong Kong Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-MX': {
    currency: {
      style: 'currency',
      currency: 'MXN',
      name:'Mexican Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-NO': {
    currency: {
      style: 'currency',
      currency: 'NOK',
      name:'Norwegian Krone',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-IL': {
    currency: {
      style: 'currency',
      currency: 'ILS',
      name:'Israeli Shekel',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-AE': {
    currency: {
      style: 'currency',
      currency: 'AED',
      name:'Emirati Dirham',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-CL': {
    currency: {
      style: 'currency',
      currency: 'CLP',
      name:'Chilean Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'it-CO': {
    currency: {
      style: 'currency',
      currency: 'COP',
      name:'Colombian Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-PT': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      name: 'Euro',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-US': {
    currency: {
      style: 'currency',
      currency: 'USD',
      name: 'United States Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-UK': {
    currency: {
      style: 'currency',
      currency: 'GBP',
      name:'Great Britain Pound',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-AU': {
    currency: {
      style: 'currency',
      currency: 'AUD',
      name:'Australian Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-BR': {
    currency: {
      style: 'currency',
      currency: 'BRL',
      name:'Brazilian Real',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-CA': {
    currency: {
      style: 'currency',
      currency: 'CAD',
      name:'Canadian Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-CN': {
    currency: {
      style: 'currency',
      currency: 'CNY',
      name:'Chinese Yuan',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-IN': {
    currency: {
      style: 'currency',
      currency: 'INR',
      name:'Indian Rupees',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-JP': {
    currency: {
      style: 'currency',
      currency: 'JPY',
      name:'Japanese Yen',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-NZ': {
    currency: {
      style: 'currency',
      currency: 'NZD',
      name:'New Zealand Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-RU': {
    currency: {
      style: 'currency',
      currency: 'RUB',
      name:'Russian Rubles',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-SG': {
    currency: {
      style: 'currency',
      currency: 'SGD',
      name:'Singapore Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-KR': {
    currency: {
      style: 'currency',
      currency: 'KRW',
      name:'South Korean Won',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-SE': {
    currency: {
      style: 'currency',
      currency: 'SEK',
      name:'Swedish Krona',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-CH': {
    currency: {
      style: 'currency',
      currency: 'CHF',
      name:'Swiss Franc',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-AR': {
    currency: {
      style: 'currency',
      currency: 'ARS',
      name:'Argentine Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-HK': {
    currency: {
      style: 'currency',
      currency: 'HKD',
      name:'Hong Kong Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-MX': {
    currency: {
      style: 'currency',
      currency: 'MXN',
      name:'Mexican Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-NO': {
    currency: {
      style: 'currency',
      currency: 'NOK',
      name:'Norwegian Krone',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-IL': {
    currency: {
      style: 'currency',
      currency: 'ILS',
      name:'Israeli Shekel',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-AE': {
    currency: {
      style: 'currency',
      currency: 'AED',
      name:'Emirati Dirham',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-CL': {
    currency: {
      style: 'currency',
      currency: 'CLP',
      name:'Chilean Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'pt-CO': {
    currency: {
      style: 'currency',
      currency: 'COP',
      name:'Colombian Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-RU': {
    currency: {
      style: 'currency',
      currency: 'RUB',
      name:'Russian Rubles',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-US': {
    currency: {
      style: 'currency',
      currency: 'USD',
      name: 'United States Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-UK': {
    currency: {
      style: 'currency',
      currency: 'GBP',
      name:'Great Britain Pound',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-AU': {
    currency: {
      style: 'currency',
      currency: 'AUD',
      name:'Australian Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-BR': {
    currency: {
      style: 'currency',
      currency: 'BRL',
      name:'Brazilian Real',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-CA': {
    currency: {
      style: 'currency',
      currency: 'CAD',
      name:'Canadian Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-CN': {
    currency: {
      style: 'currency',
      currency: 'CNY',
      name:'Chinese Yuan',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-IN': {
    currency: {
      style: 'currency',
      currency: 'INR',
      name:'Indian Rupees',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-JP': {
    currency: {
      style: 'currency',
      currency: 'JPY',
      name:'Japanese Yen',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-NZ': {
    currency: {
      style: 'currency',
      currency: 'NZD',
      name:'New Zealand Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-SG': {
    currency: {
      style: 'currency',
      currency: 'SGD',
      name:'Singapore Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-KR': {
    currency: {
      style: 'currency',
      currency: 'KRW',
      name:'South Korean Won',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-SE': {
    currency: {
      style: 'currency',
      currency: 'SEK',
      name:'Swedish Krona',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-CH': {
    currency: {
      style: 'currency',
      currency: 'CHF',
      name:'Swiss Franc',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-AR': {
    currency: {
      style: 'currency',
      currency: 'ARS',
      name:'Argentine Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-HK': {
    currency: {
      style: 'currency',
      currency: 'HKD',
      name:'Hong Kong Dollar',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-MX': {
    currency: {
      style: 'currency',
      currency: 'MXN',
      name:'Mexican Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-NO': {
    currency: {
      style: 'currency',
      currency: 'NOK',
      name:'Norwegian Krone',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-IL': {
    currency: {
      style: 'currency',
      currency: 'ILS',
      name:'Israeli Shekel',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-AE': {
    currency: {
      style: 'currency',
      currency: 'AED',
      name:'Emirati Dirham',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-CL': {
    currency: {
      style: 'currency',
      currency: 'CLP',
      name:'Chilean Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  'ru-CO': {
    currency: {
      style: 'currency',
      currency: 'COP',
      name:'Colombian Peso',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
} as const;

<template>
  <div :class="`flex ${column ? 'flex-col items-start' : 'flex-row items-center'} space-x-1`">
    <div class="flex flex-row items-center">
      <div v-if="all_filled">
        <img src="/img/icons/star_rate_filled.svg" class="icon yellow" />
      </div>
      <span v-else v-for="star in 1" :key="star" class="flex items-end">
        <img src="/img/icons/star_rate_filled.svg" v-if="star <= filledStars" class="icon yellow" />
        <img
          src="/img/icons/star_rate_half.svg"
          v-else-if="star === filledStars + 1 && hasHalfStar"
          class="icon yellow"
        />
        <img src="/img/icons/star_rate.svg" v-else class="icon yellow" />
      </span>
      <span class="text-yellow-500 font-semibold leading-tight">
        {{ [1, 2, 3, 4, 5].includes(rating) ? rating.toFixed(1) : rating > 5 ? 5 : rating?.toLocaleString() }}
      </span>
    </div>
    <div v-if="descriptive" class="pl-1">
      <span class="text-grey-600 dark:text-grey-500 leading-tight w-full">
        <Translatable
          tKey='based on <a href="{ref}" class="tx-link underline bold">{count} reviews</a>'
          :varsMap="{ ref: '#reviews-section', count: localizedNumber(reviews_count, 'decimal') }"
        />
      </span>
    </div>
    <span v-else v-if="reviews_count" class="text-sm text-gray-400 dark:text-gray-200 leading-tight"
      >({{ localizedNumber(reviews_count, 'decimal') }} {{ $t('reviews') }})</span
    >
  </div>
</template>

<script setup>
const props = defineProps({
  reviews_count: {
    type: Number,
    required: false,
  },
  rating: {
    type: Number,
    required: true,
  },
  descriptive: {
    type: Boolean,
    default: false,
  },
  all_filled: {
    type: Boolean,
    default: false,
  },
  column: { // For flex row or col
    type: Boolean,
    default: false,
  }
});
const filledStars = computed(() => Math.floor(props.reviews_count));
const hasHalfStar = computed(() => props.reviews_count - filledStars.value >= 0.5);
</script>

<style scoped>
/* Adjust star size for mobile devices */
@media (max-width: 767px) {
  .icon {
    width: 20px; /* Adjust the width for mobile devices */
    height: 20px; /* Adjust the height for mobile devices */
  }
}

/* Yellow star color */
.yellow {
  filter: invert(75%) sepia(99%) saturate(624%) hue-rotate(1deg) brightness(104%) contrast(103%);
}

/* Grey star color */
.grey {
  filter: invert(74%) sepia(3%) saturate(0%) hue-rotate(181deg) brightness(91%) contrast(90%);
}
</style>

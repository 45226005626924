<template>
  <NuxtLoadingIndicator />
  <AppHeader :data="headerData" />
  <NuxtLayout>
    <NuxtPage
      :class="[
        showPaddingX ? 'px-2 md:px-4' : '',
        fullScreen ? '' : 'mx-auto max-w-screen-xl',
        showFooter ? 'pb-20 min-h-screen pt-2 md:pt-36' : 'h-[100vh]',
      ]"
      @passDataToHeader="onPassDataToHeader"
    />
  </NuxtLayout>
  <AppFooter v-if="showFooter" />
</template>
<script setup>
const route = useRoute();

const headerData = ref({});
const { proxy } = useScriptMetaPixel();
const tracking = useTrackingId();
const runtimeConfig = useRuntimeConfig();

const { initialize } = useHotjar();

const showPaddingX = computed(() => {
  if (route?.name?.includes('product') || route?.name?.includes('map')) {
    return false;
  } else {
    return true;
  }
});

const fullScreen = computed(() => {
  if (route?.name?.includes('map')) {
    return true;
  } else {
    return false;
  }
});

const showFooter = computed(() => {
  if (route?.name?.includes('map')) {
    return false;
  } else {
    return true;
  }
});

const stop = watch(
  () => tracking.trackingId.value,
  (newTrackingId) => {
    if (newTrackingId) {
      // Initialize Hotjar && Facebook Pixel
      if (process.client) {
        initialize();
        proxy.fbq('init', runtimeConfig.public.metapixelID, {
          external_id: newTrackingId,
          extern_id: newTrackingId,
        });
        proxy.fbq('track', 'PageView');
      }
      stop();
    }
  },
);

function onPassDataToHeader(data) {
  headerData.value = data;
}

onMounted(() => {
  if (!route?.name?.includes('product')) {
    initAnyChat();
  }

  initFillout();
});

function initAnyChat() {
  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src =
      'https://api.anychat.one/widget/2c3414ed-c5f7-3fb6-a295-3e13dba23419?r=' + encodeURIComponent(window.location);
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'contactus-jssdk');
}

function initFillout() {
  const script = document.createElement('script');
  script.src = 'https://server.fillout.com/embed/v1/';
  script.async = true;
  document.body.appendChild(script);
}
</script>
<style>
h1 {
  @apply text-8xl;
  @apply font-semibold;
}
h2 {
  @apply text-4xl;
  @apply leading-8;
  @apply font-semibold;
  @apply md:text-4xl;
  @apply md:leading-10;
}
strong {
  @apply font-semibold;
}
h3 {
  @apply text-3xl;
  @apply font-semibold;
}
p {
  @apply text-xl;
  @apply leading-8;
}
a {
  @apply text-Tourscanner_blue-source hover:dark:text-gray-400;
}

.ts-link {
  @apply text-Tourscanner_blue-source hover:dark:text-gray-400 underline cursor-pointer;
}
li {
  @apply text-xl;
  @apply leading-8;
}
.ts-button {
  @apply bg-gradient-to-r from-ts_blue_to to-ts_blue_from;
  @apply rounded-full px-4 py-2;
  @apply text-white;
}

body {
  @apply dark:bg-[#121212] dark:text-white;
}

/* ON mobile hide the default contact us button */
@media (max-width: 767px) {
  #arcontactus {
    display: none !important;
  }
}
</style>
